import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {cms_img} from '../static';

export default class MSSubOverview extends Component {

	constructor(props){
		super(props);

		const _ = this;
		_.to = null;
		_.state = {
			animateList: ""
		};

	}

	componentDidMount(){
		const _ = this;
		_.setState({
			animateList: "SMI-list__animate-li"
		});
	}

	render(){
		const _ = this;
		const content = _.props.content;

		return(
			<div className={`page__holder page__tpl-questions ${_.props.pageAnimation}`}>
				<header className="header header-std">
					<div className="row flex-m">
						<div className="col-12">
							<figure className="header-std__icon" aria-hidden="true">
								<img src={cms_img(content.MainIcon)} alt="" />
							</figure>
							<div className="header-std__title">
								<h1 className="m-b-0">
									<span className="header-std__title-lg-span">{content.MainTitleLg}</span><br />
									<span className="header-std__title-sm-span">{content.MainTitleSm}</span>
								</h1>
							</div>
						</div>
					</div>
				</header>

				<div className="container">
					<div className="row flex-l">

					{[].map.call(_.props.childPageData,(item,index)=>{
						return (<LaunchPadBox
							content={item}
							key={`launchpad-${index}`}
						/>);
					})}
					</div>
				</div>
			</div>
		);
	}
}

class LaunchPadBox extends Component{

	render(){
		const _ = this;
		const content = _.props.content;

		return(
			<div className="col-6 box-overview__outer">
				<section className="box box-overview box-overview__sub">
					<header>
						<img src={cms_img(content.MainIcon)} alt="" aria-hidden="true" className="box-overview__icon" />
						<h2 className="box-overview__header box-overview__header-sub">{content.MainTitleLg}</h2>
						<p className="box-overview__subheader box-overview__subheader-sub">{content.MainTitleSm}</p>
					</header>

					<div className="box-overview__sub-overview-content font-size-1p125">
						<div className="list__arrows">
							{ReactHtmlParser(content.Summary)}
						</div>

						<footer className="text-r uppercase font-size-0p75">
							<a href={content.NodeAliasPath}><strong>Learn More &gt;</strong></a>
						</footer>
					</div>
				</section>
			</div>
		);
	}
}
