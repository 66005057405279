import React, { Component,createRef } from 'react';
import {cms_img} from './static';

export default class Background extends Component {

	constructor(props){
		super(props);

		const _ = this;
		_.bg = createRef();

		_.state = {
			mounted: false
		};
	}

	componentDidMount(){
		const _ = this;
		_.setState({
			mounted: true
		});
	}

	updateBgAnimation(){
		if(this.state.mounted){
			const _ = this;
			const bgClass = _.bg.current.classList;

			bgClass.add(_.props.animateBg);
			bgClass.remove('bg__in');

			setTimeout(()=>{
				bgClass.remove(_.props.animateBg);
				bgClass.add('bg__in');
			},700);
		}
	}

	render() {
		const _ = this;
		_.updateBgAnimation();

		return (
			<div className="bg__outer">
				<figure
					ref={_.bg}
					className={`bg ${_.props.animateBg}`}
					style={{
						backgroundImage: `url(${cms_img(_.props.url)}`,
					}}
				/>
			</div>
		)
	}
}
