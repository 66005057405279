//
// Globals
//

const PRODUCTION = process.env.NODE_ENV !== 'production' ? false : true;

// const PUBLIC_URL = !PRODUCTION ? process.env.PUBLIC_URL : "/App_Themes/Media/MCPc/";
const PUBLIC_URL = !PRODUCTION ? 'http://mcpcdigitalselltool.s-hileman.biz/' : "";
const VERSION = process.env.REACT_APP_VERSION;

// console.log('PUBLIC URL',PUBLIC_URL)

const REST_PARAMS = {
	navigation: [
		"orderby=nodeLevel,nodeOrder",
		"columns=NodeAliasPath",
		"selectonlypublished=true",
		"maxrelativelevel=2"
	].join('&'),
	pages: [
		"orderby=nodeorder",
		"classnames=*"
	].join('&')
};

const REST_SERVICE = {
	urls: {
		pages:`${PUBLIC_URL}/rest/content/currentsite/en-us/all/?format=json&${REST_PARAMS.pages}`,
		formPreMeeting: `${PUBLIC_URL}/rest/bizformitem.bizform.PreMeetingDetails/?format=json`,
		navigation:`${PUBLIC_URL}/rest/content/currentsite/en-us/all/?format=json&${REST_PARAMS.navigation}`
	},
	getParams: {
		method: 'GET',
		credentials: 'include',
		mode:'cors',
		headers: {
			"Authorization" : `Basic UkVTVF9Vc2VyOkdyZWVubWFwMjg=`
		}
	}
};

const DEFAULT_PATH = "/form";
const DEFAULT_PAGE = {
	PageAnimation:"page__animate-fade",
	BgUrl:"",
	ClassName:'ERROR'
};

const USE_FILL_IN_VALS = !PRODUCTION;
const FORM_FIELDS = [
	{
		label:"Company Name",
		placeholder: "Enter Company Name",
		id: "companyName",
		value: "Hileman",
		errMsg: "The Company field is empty."
	}, {
		label:"Client Full Name",
		placeholder: "Enter Client Full Name",
		value: "Joe Smith",
		id: "clientFullName"
	}, {
		label:"Role/Title",
		placeholder: "Enter Role/Title",
		value: "Worker",
		id: "roleTitle"
	}, {
		label:"Client Email",
		placeholder: "Enter Client Email",
		id: "email",
		value: "kschroeder@hileman.biz",
		errMsg: "Please enter a proper email.",
		validator: "email"
	}, {
		label:"Client Phone",
		placeholder: "Enter Client Phone",
		id: "phone",
		value: "440-867-3565",
		errMsg:"Please enter a proper phone number.",
		validator: "phone"
	}
];

const QUESTION_CHECKBOXES = [
  {
    name: 'freeTime',
    key: 'freeTime',
    label: 'Does your internal IT team spendenough time on data security?',
  },
  {
    name: 'criticalData',
    key: 'criticalData',
    label: 'Do you know where all your critical data is stored?',
  },
  {
    name: 'proveProtection',
    key: 'proveProtection',
    label: 'Can you prove that it’s protected?',
  },
  {
    name: 'regulatoryCompliance',
    key: 'regulatoryCompliance',
    label: 'Can you prove regulatory compliance?',
  },
];

const FORM_REGEX = {
	phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
	email: /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
	notEmpty: /(.|\s)*\S(.|\s)*/
};

const cms_img = img => `${PUBLIC_URL}${img ? img.replace(/^~/,'') : ""}`;
const cms_link = link => link ? link.replace(/^(~)|(\.aspx)/g,'') : "";

export default PRODUCTION;
export {
	VERSION,
	REST_SERVICE,
	PUBLIC_URL,
	DEFAULT_PATH,
	DEFAULT_PAGE,
	FORM_FIELDS,
	FORM_REGEX,
	QUESTION_CHECKBOXES,
	USE_FILL_IN_VALS,
	cms_img,
	cms_link
}
