import React, { Component } from 'react';
import Popup from 'react-popup';
import {VERSION,FORM_FIELDS, FORM_REGEX,USE_FILL_IN_VALS} from '../static';
import formEntries from '../Components/formEntries.js';

const fillOutFormPopup = () =>{
	Popup.create(
		{
			title: `Please fill out the form`,
			content: `The form is incomplete please finish filling in an entry`,
			className: 'pre-meeting',
			buttons: {
				left: ['ok']
			}
		}
	)
}

export default class FormPage extends Component {

	constructor(props){
		super(props);

		const _ = this;
		const errorDefaultState = {};
		const initFormEntry = {};

		[].map.call(FORM_FIELDS,(e,i)=>{
			if(USE_FILL_IN_VALS){
				errorDefaultState[e.id] = true;
				initFormEntry[e.id] = e.value;
			}else{
				errorDefaultState[e.id] = null;
			}
		});

		_.regex = FORM_REGEX;

		_.state = {
			errors: errorDefaultState,
			currentFormEntry: initFormEntry
		};
		_.validateField  = _.validateField.bind(_);
		_.addClientClick = _.addClientClick.bind(_);
		_.startClick     = _.startClick.bind(_);
	}

	validateField(id,target,pattern){
		const _ = this;
		const isValid = _.regex[pattern].test(target.value.trim());
		const tObj = {};
		const entry = {};

		entry[id] = target.value;
		tObj[id] = isValid;

		_.setState({
			errors: Object.assign(_.state.errors,tObj),
			currentFormEntry: Object.assign(_.state.currentFormEntry,entry)
		});
	}

	addClientClick(e){
		const _ = this;
		_.addPreMeetingEntry(e);

	}

	startClick(e){
		const _ = this;
		_.addPreMeetingEntry(e,true);

	}

	addPreMeetingEntry(e,startClick = false){
		const _ = this;
		const stateErrs = _.state.errors;
		let errors = false;

		for(let i in stateErrs){
			if ( {}.hasOwnProperty.call(stateErrs,i) ){
				if( !stateErrs[i] ){
					errors = true;
				}
			}
		}

		if ( !errors ){

			const copyFormEntries = [].slice.call( formEntries.get() ,0);

			copyFormEntries.push(_.state.currentFormEntry);
			formEntries.set(copyFormEntries);

			for(let i in stateErrs){
				if ( {}.hasOwnProperty.call(stateErrs,i) ){
					stateErrs[i] = null;
				}
			}

			_.setState({
				formEntry: {},
				errors: stateErrs
			},()=>{

				if(startClick){
					_.props.routeTo('/splash');
				}
			});

			//clear the form entries in the form
			[].map.call(FORM_FIELDS,(e,i)=>{
				_[e.id].clear();
			});

		} else {
			// const alreadyHaveAnEntry = window.formEntries && window.formEntries.length;

			if( formEntries.haveAnEntry() ){

				_.props.routeTo('/splash');

			} else {
				fillOutFormPopup();
			}
		}

		e.preventDefault();
	}


	render(){
		const _ = this;

		return(
			<div className={`page__holder page__tpl-form flex-m ${_.props.pageAnimation}`}>
				<div className="wide-12">
					<header className="header pre-meeting__header text-c">
						<div className="pre-meeting__logo" />
						<p className="font-size-1p125">{_.props.content.SubHeader}</p>
					</header>

					<div className="pre-meeting__form form container" >
						{
							[].map.call(FORM_FIELDS,(item,i) =>
								<FormTextField
									key={item.id}
									item={item}
									formEntry={_.state.formEntry}
									ref={e => _[item.id] = e}
									errors={_.state.errors}
									onBlur={_.validateField}
								/>
							)
						}
						<div className="form__row form__submit-row">
							<button type="text"
								className="btn pre-meeting__btn btn__outline"
								onClick={_.addClientClick}
							>+ Add Client</button>

							<button type="text"
								className="btn pre-meeting__btn"
								onClick={_.startClick}
							>Start</button>
						</div>
					</div>
					<p className="version text-c">Version {VERSION} </p>
				</div>
			</div>
		);
	}
}


class FormTextField extends Component {
	clear() {
    this.input.value = '';
  }

	render(){
		const _ = this;
		const defaultProps = {
			label:'',
			placeholder:'',
			id:'',
			errMsg:"Please complete this field.",
			type:'text',
			validator: "notEmpty"
		};

		const item = Object.assign(defaultProps,_.props.item);
		const ifErrors = (!_.props.errors[`${item.id}`] && _.props.errors[`${item.id}`] !== null );

		return (
			<div className="form__row" >
				<label htmlFor={item.id}>{item.label}</label>
				<input
					id={item.id}
					name={item.id}
					placeholder={item.placeholder}
					ref={input => _.input = input}
					type={item.type}
					defaultValue={USE_FILL_IN_VALS ? item.value : ""}
					className="input-text"
					onBlur={(e) => _.props.onBlur(item.id,e.target,item.validator)}
				/>

				{ ifErrors && <p className="form__error">{item.errMsg}</p> }
			</div>
		);
	}
}
