import React, { Component } from 'react';


export default class ErrorPage extends Component {
	render(){
		//const content = this.props.content;
		// const _ = this;
		return(
			<div className={`page__holder page__tpl-error text-c flex-m page__animate-fade`}>
				<div className="wide-12">
					<header className="header header-std">

						<div className="header-std__title">
							<h1>Page Not Found</h1>
						</div>
					</header>

					<div className="font-size-2p0">
						<p>The page doesn't exist. Go back to the <a href="/splash">Splash Page</a> or the <a href="/">Home Page</a>, and maybe the <a href="/form">Form page</a>.
						</p>
					</div>
				</div>
			</div>
		);
	}
}
