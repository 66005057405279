import React, { Component } from 'react';
import Background      from './Background';

//---------------------
//Actual Page Templates
//---------------------
import FormPage        from './Templates/FormPage';
import Home            from './Templates/Home';
import Splash          from './Templates/Splash';
import Questions       from './Templates/Questions';
import SMILander       from './Templates/SMILander';
import SMIContent      from './Templates/SMIContent';
import MSOverview      from './Templates/MSOverview';
import MSSubOverview   from './Templates/MSSubOverview';
import ServiceDetail   from './Templates/ServiceDetail';
import ErrorPage       from './Templates/ErrorPage';


export default class Pages extends Component {

	constructor(props){
		super(props);

		const _ = this;
		_.to = null;
		_.state = {
			animatePg: null,
			animateBg: null
		};
		_.parentPageData = _.parentPageData.bind(_);
		_.childPageData  = _.childPageData.bind(_);
	}

	componentDidMount(){
		const _ = this;
		const page = _.props.pageToRender;


		_.to = setTimeout(()=>{
			_.setState({
				animatePg: page.PageAnimation
			});
		}, 300);
	}

	componentWillUnmount() {
		const _ = this;
		clearTimeout(_.to);

	}

	parentPageData(){
		const _ = this;
		const content = _.props.pageToRender;
		return _.props.getPageTemplateAndContent(
			content.NodeAliasPath.slice(0,(content.NodeAliasPath.lastIndexOf('/')) )
		);
	}

	childPageData(){
		const _ = this;
		const content = _.props.pageToRender;

		return _.props.getPageTemplateAndContent( content.NodeAliasPath, true);
	}

	chooseTemplate(){
		const _ = this;
		let template = null;
		const page = _.props.pageToRender;
		// console.log(page.ClassName);
		switch( page.ClassName ){

			case "MCPc.FormPage":
				template = <FormPage
					content={page}
					routeTo={_.props.routeTo}
					pageAnimation={_.state.animatePg}
				/>;
			break;

			case "MCPc.Splash":
				template = <Splash content={page} pageAnimation={_.state.animatePg} />;
			break;

			case "MCPc.Home":
				template = <Home content={page} pageAnimation={_.state.animatePg} />;
			break;

			case "MCPc.Questions":
				template = <Questions
					content={page}
					pageAnimation={_.state.animatePg}
					routeTo={_.props.routeTo}
					submittedUserDetails={_.props.submittedUserDetails}
					submittedUserDetailsFn={_.props.submittedUserDetailsFn}
				/>;
			break;

			case "MCPc.SMILander":
				template = <SMILander content={page} pageAnimation={_.state.animatePg} />;
			break;

			case "MCPc.SMIContent":
				template = <SMIContent
					content={page}
					pageAnimation={_.state.animatePg}
					parentPageData={_.parentPageData}
				/>;
			break;

			case "MCPc.MSOverview":
				template = <MSOverview
					content={page}
					pageAnimation={_.state.animatePg}
					childPageData={_.childPageData()}
				/>;
			break; //MSSubOverview

			case "MCPc.MSSubOverview":
				template = <MSSubOverview
					content={page}
					pageAnimation={_.state.animatePg}
					childPageData={_.childPageData()}
				/>;
			break;

			case "MCPc.ServiceDetail":
				template = <ServiceDetail content={page} pageAnimation={_.state.animatePg} />;
			break;

			case "ERROR":
				template = <ErrorPage content={page} pageAnimation={_.state.animatePg} />;
			break;

			default:
				template = <Splash content={page} pageAnimation={_.state.animatePg} />;
			break;
		}

		return template;
	}

	render(){
		const _ = this;
		const page = _.props.pageToRender;

		return (
			<>
			<Background
				url={page.BgUrl}
				animateBg={page.BgAnimation}
			/>
			{_.chooseTemplate()}
			</>
		);
	}
}
