import React, { Component } from 'react';
import {cms_img,cms_link} from '../static';


export default class Home extends Component {

	renderNavItem( link, iconSrc, title, iconPosition , position ){

		const iconLink =
			<a href={cms_link(link)} className="solutions__nav-anchor-icon">
				<img src={cms_img(iconSrc)} aria-hidden="true" alt=""/>
			</a>
		;

		return (
			<div className={`col-6 solutions__nav-box solutions__nav-box-${position} flex-m`}>
				<div className={`solutions__nav-box-inner solutions__nav-box-inner-${position}`}>
					{iconPosition === "TOP" && iconLink}
					<div className={`solutions__line solutions__line-${position}`}>
						<h2><a href={cms_link(link)} className="no-decoration">{title}</a></h2>
						<div className="pointer-line"></div>
					</div>
					{iconPosition === "BOTTOM" && iconLink}
				</div>
			</div>
		);
	}

	render(){
		const _ = this;
		const content = _.props.content;

		return(
			<div className={`page__holder text-c ${_.props.pageAnimation}`}>
				<div className="solutions__wheel-base" />
				<div className="solutions__wheel-ring-nav">

					{[1,2,3,4].map((e)=>
						<div key={`wheelRing${e}`} className={`solutions__wheel-ring solutions__wheel-ring-${e}`} />
					)}

					<div className="solutions__wheel-center-icon" />
				</div>
				<div className="container solutions__nav-outer">
					<div className="row flex-l solutions__nav">
						{_.renderNavItem(
							content.Link3Href,
							content.Link1Icon,
							content.Link1Title,
							'BOTTOM',
							'top-l'
						)}
						{_.renderNavItem(
							content.Link3Href,
							content.Link2Icon,
							content.Link2Title,
							'BOTTOM',
							'top-r'
						)}
						{_.renderNavItem(
							content.Link3Href,
							content.Link3Icon,
							content.Link3Title,
							'TOP',
							'bottom-l'
						)}
						{_.renderNavItem(
							content.Link3Href,
							content.Link4Icon,
							content.Link4Title,
							'TOP',
							'bottom-r'
						)}
					</div>
				</div>
			</div>
		);
	}
}
