
function postToForm(url,data){
	new Promise(function(resolve, reject) {
		fetch(url,{
			method: "POST",
			credentials: 'include',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				"Authorization" : `Basic UkVTVF9Vc2VyOkdyZWVubWFwMjg=`
			},
			body: JSON.stringify(data)
		})
		.then(response => response.json() )
		.then(json => resolve(json));
	});
}

export default postToForm;
