import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {cms_link,cms_img} from '../static';


export default class SMILander extends Component {

	constructor(props){
		super(props);

		const _ = this;

		_.state = {
			animatePage: "",
			animateList: ""
		};

	}

	componentDidMount(){
		const _ = this;
		_.setState({
			animateList: "SMI-list__animate-li"
		});
	}


	render(){
		const _ = this;
		const content = _.props.content;

		return(
			<div className={`page__holder page__tpl-questions ${_.props.pageAnimation}`}>
				<header className="header header-std">
					<figure className="header-std__icon" aria-hidden="true">
						<img src={cms_img(content.MainIcon)} alt="" />
					</figure>
					<div className="header-std__title">
						<h1>{content.MainTitle}</h1>
					</div>
				</header>

				<div className="font-size-1p5 col-8">
					{ReactHtmlParser(content.BodyContent)}

					{/* Make it dynamic.. I think*/}
					<ul className={`SMI-list__ul`}>
						<li className={`SMI-list__li ${_.state.animateList}`}>
							<a href={cms_link(content.ListAnchorSee)}>
								<i className="icon i-eye" aria-hidden="true"/>
								<strong>See </strong>
							</a>
						</li>
						<li className={`SMI-list__li ${_.state.animateList}`}>
							<a href={cms_link(content.ListAnchorMeasure)}>
								<i className="icon i-check-list" aria-hidden="true"/>
								<strong>Measure </strong>
							</a>
						</li>
						<li className={`SMI-list__li ${_.state.animateList}`}>
							<a href={cms_link(content.ListAnchorImprove)}>
								<i className="icon i-chart-up" aria-hidden="true"/>
								<strong>Improve </strong>
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
