import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {cms_img} from '../static';

export default class ServiceDetail extends Component {

	constructor(props){
		super(props);

		const _ = this;
		_.to = null;
		_.state = {
			animateList: ""
		};

	}

	componentDidMount(){
		const _ = this;
		_.setState({
			animateList: "SMI-list__animate-li"
		});
	}

	render(){
		const _ = this;
		const content = _.props.content;
		const MainheadText = content.MainHeadText ?
			<div className="md-col-8 v-space-b">
				<div className="font-size-1p5 border-left__std">
					{content.MainHeadText}
				</div>
			</div> : "";

		const BodyTextSecondary = content.BodyTextSecondary ?
		<div className="md-col-5 list__checkboxes">
			{ReactHtmlParser(content.BodyTextSecondary)}
		</div> : "";

		return(
			<div className={`page__holder page__tpl-questions ${_.props.pageAnimation}`}>
				<header className="header header-std">
					<div className="row flex-m">
						<div className="col-12">
							<figure className="header-std__icon" aria-hidden="true">
								<img src={cms_img(content.MainIcon)} alt="" />
							</figure>
							<div className="header-std__title">
								<h1 className="m-b-0">
									<span className="header-std__title-lg-span">{content.MainTitleLg}</span><br />
									<span className="header-std__title-sm-span">{content.MainTitleSm}</span>
								</h1>
							</div>
						</div>
					</div>
				</header>

				<div className="container body-content">
					<div className="row ">
						{MainheadText}
						<div className="md-col-7">
							{ReactHtmlParser(content.BodyText)}
						</div>
						{BodyTextSecondary}
					</div>
				</div>
			</div>
		);
	}
}
