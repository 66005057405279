import React, { Component } from 'react';
import PRODUCTION, {REST_SERVICE,QUESTION_CHECKBOXES,cms_img} from '../static';
import postToForm from '../Components/post-to-biz-form.js';
import Checkbox from '../Components/Checkbox.js';
import formEntries from '../Components/formEntries.js';

export default class Questions extends Component {
	constructor(props){
		super(props);
		const checkedItemsDefault = {};

		for(let item in QUESTION_CHECKBOXES ){
			checkedItemsDefault[QUESTION_CHECKBOXES[item].name] = false;
		}

		const _ = this;
		_.state = {
			checkedItems: checkedItemsDefault
		};
		_.handleCheckboxChange = _.handleCheckboxChange.bind(_);
		_.postPreMeetingDetailsInfo = _.postPreMeetingDetailsInfo.bind(_);
	}

	postPreMeetingDetailsInfo(){
		const _ = this;

		if(! _.props.sumbittedUserDetails) {
			[].forEach.call(formEntries.get(),(entry,i)=>{

				if(PRODUCTION){
					postToForm(REST_SERVICE.urls.formPreMeeting,entry);
				} else {
					console.log('FORM ENTRIES',{...entry,..._.state.checkedItems});
				}
			});
		}

		//
		_.props.submittedUserDetailsFn();

	}

	handleCheckboxChange(e) {
		const _ = this;
		const item = e.target.name;
		const isChecked = e.target.checked;
		const checkedItems = {};

		for(let i in _.state.checkedItems ){
			checkedItems[i] = item === i ? isChecked : _.state.checkedItems[i];
		}

		_.setState({
			checkedItems: checkedItems
		},()=>{
			console.log(_.state.checkedItems);
			window.checkedItems = _.state.checkedItems;
		});
	}

	render(){
		const _ = this;
		const content = this.props.content;
		const submitBtn = <button
			className="btn"
			type="text"
			onClick={_.postPreMeetingDetailsInfo}>
			Submit User Information
		</button>;
		
		return(
			<div className={`page__holder page__tpl-questions ${_.props.pageAnimation}`}>
				<header className="header header-std">
					<figure className="header-std__icon" aria-hidden="true">
						<img src={cms_img(content.MainIcon)} alt="" />
					</figure>
					<div className="header-std__title">
						<h1>{content.MainTitle}</h1>
					</div>
				</header>

				<div className="check-item">
					<ul className="check-item__ul inline-ul block-li">

						{
							[].map.call(QUESTION_CHECKBOXES, (item,index) => (
								<li key={item.key} className={index === QUESTION_CHECKBOXES.length-1 ? 'last-item' : ''}>
									<Checkbox
										name={item.name}
										checked={_.state.checkedItems[item.name]}
										onChange={_.handleCheckboxChange}
										label={item.label}
										className={`checkbox__cirlce`}
									/>
								</li>
							))
						}
					</ul>
				</div>
				<p className="h1">How does MSS solve for this? {_.props.submittedUserDetails}
				<i className="icon i-nav-arrow-r btn__link-arrow" aria-hidden="true" /></p>

				{!_.props.submittedUserDetails && submitBtn}
			</div>
		);
	}
}
