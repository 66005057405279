import React, { Component } from 'react';
import Popup from 'react-popup';
// import renderHTML from 'react-render-html';
// import {PUBLIC_URL} from '../static';


export default class Splash extends Component {
	render(){
		const _ = this;
		const content = _.props.content;

		return(
			<div className={`page__holder ${_.props.pageAnimation}`}>
				<header className="splash__header text-c">
					<div className="splash__logo">
						<span className="sr-only">MCPc Logo</span>
					</div>
					<div className="splash__tagline">
						<span className="sr-only">The Data Protection Company</span>
					</div>
				</header>
				<p className="splash__title text-c">Achieve <strong>SecurityCertainty</strong><sup>&#8480;</sup></p>

				<div className="spash__navigation text-c">
					<ul className="inline-ul splash__nav-ul">
						<li>
							<button className="btn__link" onClick={() => Popup.create(
								{
									title: `${content.VideoTitle}`,
									content: <iframe
										width={content.VideoWidth}
										title="video"
										height={content.VideoHeight}
										src={content.VideoURL}
										frameBorder="0"
										allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
									/>,
								}
							)}>
									<i className="icon i-play-btn" aria-hidden="true" ></i>
									<span>Watch Video</span>
								</button>
						</li>
						<li>
							<a href="/home">
								<i className="icon i-solutions" aria-hidden="true"></i>
								<span>Solutions</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
