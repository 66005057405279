import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {cms_img} from '../static';


export default class SMIContent extends Component {

	constructor(props){
		super(props);

		const _ = this;

		_.state = {
			animatePage: "",
			animateList: ""
		};

	}

	componentDidMount(){
		const _ = this;
		_.setState({
			animateList: "SMI-list__animate-li"
		});
	}


	render(){
		const _ = this;
		const content = _.props.content;
		const parentPage = _.props.parentPageData();

		return(
			<div className={`page__holder page__tpl-questions ${_.props.pageAnimation}`}>
				<header className="header header-std">
					<figure className="header-std__icon" aria-hidden="true">
						<img src={cms_img(content.MainIcon)} alt="" />
					</figure>
					<div className="header-std__title">
						<h1>{content.MainTitle}</h1>
					</div>
				</header>

				<div className="font-size-1p5 col-8">
					<div className="list__checkboxes">
						{ReactHtmlParser(content.BodyContent)}
					</div>
				</div>
				<div className="footer__parent-page-title-icon">
				<figure className="footer__icon" aria-hidden="true">
					<img src={cms_img(parentPage.MainIcon)} alt="" />
				</figure>
				<h2>{parentPage.MainTitle}</h2>
				</div>
				{/*TODO add bottom tag*/}
			</div>
		);
	}
}
