//ie 10 support
import 'core-js/es/map';
import 'core-js/es/set';

import React, { Component } from 'react';
import SellTool from './scripts/SellTool.js';

import './css/main.css';


class App extends Component {
	render() {
		return (
			<SellTool />
		);
	}
}

export default App;
