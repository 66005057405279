import React, { Component } from 'react';

export default class Navigation extends Component {

	render() {

		const _  = this;
		let navigation = null;
		const excludePages = /(\/form|\/splash)/;
		
		if( !excludePages.test(_.props.nodeAliasPath) ){
			navigation = <nav className="primary-nav">
				<ul className="primary-nav__ul">

					<li><a href="/home">
							<i className="icon i-nav-home"><span className="sr-only">Home</span></i>
						</a>
					</li>
					<li>
						<button type="text" className="btn__link" onClick={_.props.backClick} >
							<i className="icon i-nav-arrow-l">
								<span className="sr-only">Back</span>
							</i>
						</button>
					</li>
					<li>
						<button type="text" className="btn__link" onClick={_.props.nextClick}>
							<i className="icon i-nav-arrow-r">
								<span className="sr-only">Next</span>
							</i>
						</button>
					</li>
				</ul>
			</nav>
		}

		return navigation;

	}
}
