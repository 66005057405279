
import React, { Component,createRef } from 'react';
import Pages from './Pages';
import Navigation from './Navigation';
import Popup from 'react-popup';
import PRODUCTION, {DEFAULT_PATH, DEFAULT_PAGE, REST_SERVICE} from './static';
//https://www.freecodecamp.org/news/you-might-not-need-react-router-38673620f3d/



export default class SellTool extends Component {

	constructor(props) {
		super(props);
		const _ = this;
		_.app = createRef();
		_.cmsPageData = null;
		_.fetchingCMSData = true;
		_.state = {
			pageToRender: null,
			currentPathName: DEFAULT_PATH,
			sumbittedUserDetails: false,
			nextPgs: [],
			history: {
				pages: [],
				position: 0
			}
		};

		_.fetchCMSData();
		_.fetchCMSBottomNav();
		_.onPopState();

		_.navigationClick           = _.navigationClick.bind(_);
		_.backBtnClick              = _.backBtnClick.bind(_);
		_.nextBtnClick              = _.nextBtnClick.bind(_);
		_.routeTo                   = _.routeTo.bind(_);
		_.getPageTemplateAndContent = _.getPageTemplateAndContent.bind(_);
		_.submittedUserDetails      = _.submittedUserDetails.bind(_);
	}

	onPopState(e){
		const _ = this;
		window.addEventListener('popstate',()=>{
			const path = window.location.pathname;
			console.log('popstated',path);
			_.routeTo(path,false,true);
		})
	}

	fetchCMSData(){
		const _ = this;

		let data = new Promise(function(resolve, reject) {
			//https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
			fetch(
				REST_SERVICE.urls.pages,
				REST_SERVICE.getParams
			)
			.then(response => response.json())
			.then(json => resolve(json));
		});
		data.then((data)=> {
			_.cmsPageData = data.cms_documents;//[0].cms_document;
			_.fetchingCMSData = false;
			const pathname = window.location.pathname;

			const isIndex = pathname === "/" || pathname === "/index.html";
			_.routeTo( isIndex ? DEFAULT_PATH : pathname );
		});
	}

	fetchCMSBottomNav(){
		const _ = this;

		let data = new Promise(function(resolve, reject) {
			//https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
			fetch(
				REST_SERVICE.urls.navigation,
				REST_SERVICE.getParams
			)
			.then(response => response.json())
			.then(json => resolve(json));
		});
		data.then((data)=> {

			const pagesArray = data.cms_documents[0].cms_document;

			_.setState({
				nextPgs: pagesArray.slice(1)//remove the first entry which is the 'root'
			},()=>{
				// console.log('NEXT PAGES',_.state.nextPgs);
			})
		});
	}

	getPageTemplateAndContent( pathName, getChildPages = false ){
		const _ = this;
		if ( _.fetchingCMSData ) {
			return;
		}

		let page = DEFAULT_PAGE;
		const childPages = [];//can be a collection of pages

		for(let i = 0,l = _.cmsPageData.length; i < l; i++){

			let d = _.cmsPageData[i];
			let docPageTypeName = Object.keys( d )[0];//Polyfill for Object.keys?
			let doc = d[ docPageTypeName ];

			for(let j = 0,ll = doc.length;j<ll;j++){
				let pageType = doc[j];

				if( getChildPages ){
					let isImmediateChild = pageType.NodeAliasPath.split('/').length-1 === pathName.split('/').length;
					let parentPathMatches = pageType.NodeAliasPath.trim().slice(0,pathName.length) === pathName;
					let isntThePageWereOn = pageType.NodeAliasPath.trim() !== pathName;

					if( isImmediateChild && parentPathMatches && isntThePageWereOn ){
						childPages.push(pageType);
					}

				} else {
					if(pathName === pageType.NodeAliasPath.trim()){
						page = pageType;
						// console.log(page,pathName)
						break;
					}
				}
			}
		}

		return getChildPages ? childPages : page;
	}

	routeTo(pathName, goBack = false,popstate = false){

		const _ = this;
		const page = _.getPageTemplateAndContent( pathName );
		const hist = _.state.history;

		let hPos = hist.position;
		const hPages = hist.pages;

		if( goBack ){
			hPos > 1 ? --hPos : hPos = 1;
			hPages.pop();
		} else {
			hPos++;
			hPages.push(pathName);
		}


		!PRODUCTION && console.log(pathName);

		_.setState({
			pageToRender: page,
			currentPathName: pathName,
			history: {
				position: hPos,
				pages: hPages
			}
		},()=>{
			if(window.location.pathname !== '/admin' && !popstate){

				window.history.pushState(page, "MCPC Digital Sell Tool", pathName);
			}
		});
	}

	navigationClick(e){

		const _ = this;

		const target = e.target;
		if( target.href || target.parentNode.href ) {
			//if it's an anchor tag with an href or the immediate
			//parent of the target neslted in the anchor
			//this may need to get updated at some point
			const pathName =  target.pathname || target.parentNode.pathname;
			_.routeTo(pathName);
			e.preventDefault();
		}
	}

	backBtnClick(){
		const _ = this;

		const hist = _.state.history;
		const hPos = hist.position;

		const pathName = hist.pages[hPos-2];
		_.routeTo(pathName,true);
	}

	nextBtnClick(){
		const _ = this;
		const nextPgs = _.state.nextPgs;
		const currPathName = _.state.currentPathName;
		let nextPgIndex = 0;// = nextPgs.indexOf(currPathName);

		for(let i = 0,l = nextPgs.length; i < l; i++){
			if( nextPgs[i].NodeAliasPath === currPathName ){
				nextPgIndex = i;
				break;
			}
		}

		if( nextPgIndex === -1 || nextPgIndex === nextPgs.length-1 ){
			//If we're the last page (plus one)
			//or not found lets start at square one
			//lets not start at the form page
			nextPgIndex = 1;
		}

		const nextPathName = nextPgs[nextPgIndex+1].NodeAliasPath;
		_.routeTo(nextPathName,false,true);
	}

	submittedUserDetails(){
		const _ = this;
		_.setState({
			submittedUserDetails: true
		});
	}

	render() {

		const _ = this;
		let page = <div className="loader__outer"><div className="loader" /></div>;
		let navigation = null;

		if( _.state.pageToRender !== null ){
			page = <>
				<Pages
					pageToRender={_.state.pageToRender}
					getPageTemplateAndContent={_.getPageTemplateAndContent}
					routeTo={_.routeTo}
					formEntries={_.formEntries}
					cmsPageData={_.cmsPageData}
					submittedUserDetails={_.state.submittedUserDetails}
					submittedUserDetailsFn={_.submittedUserDetails}
				/>
			</>
		}

		if( _.state.nextPgs.length !== 0  && _.state.pageToRender !== null){
			navigation = <>
				<Navigation
					nodeAliasPath={_.state.pageToRender.NodeAliasPath}
					history={_.history}
					backClick={_.backBtnClick}
					nextClick={_.nextBtnClick}
				/>
			</>
		}

		return (
			<div className="app" ref={_.app} onClickCapture={_.navigationClick}>
				{page}
				{navigation}
				<Popup />
			</div>
		);
	}
}
