import React from 'react';

const Checkbox = ({ name, label, checked, onChange, className }) => (
	<div className={`checkbox__holder`}>
		<div className={`checkbox__wrapper ${className}`}>
			<input type={'checkbox'}
				name={name}
				id={name}
				value={checked}
				onChange={onChange}
				className={`checkbox__itself`}
				tabIndex={0}
			/>
			<i className={`checkbox__faux icon i-checkmark-sm checkbox__faux-${checked}`}></i>
		</div>
		<label  htmlFor={name} className={`checkbox__label`} >
			<span>{label}</span>
		</label>
	</div>
);

export default Checkbox;
