
const formEntries = {
	get: function(){
		window.formEntries = window.formEntries || [];
		return window.formEntries;
	},
	set: function(entry){
		console.log(entry)
		window.formEntries = entry;
	},
	haveAnEntry: function(){
		return window.formEntries && window.formEntries.length;
	}
};

export default formEntries;
